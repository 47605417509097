<template>
<p>{{$t('HOME_PAGE_MESSAGE') | capitalize}}</p>
</template>

<script>
import {CapitalFilter} from "@/mixins";

export default {
  name: "HomeComponent",
  mixins: [CapitalFilter]
}
</script>

<style scoped>

</style>